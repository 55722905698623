/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-vars */
<template>
 <label>Title: </label>
 <input class="text mb-1 ms-1" v-model="title">
 <br>
 <label>Message: </label>
 <input class="text mb-1 ms-1" v-model="message">
 <br>
 <button @click.prevent="addToast" class="btn btn-primary">Submit</button>
 <Spinner />
 <ActionButton @click="alert" />
 <router-link :to="{name: 'NotesEdit', params: {id: -1}}" tag="a" >Notes</router-link>
</template>

<script>
 import Spinner from '@/components/Spinner.vue'
 import ActionButton from '@/components/ActionButton.vue'
 
 export default {
     name: 'About',
     components: {
         Spinner,
         ActionButton,
     },
     data() {
         return {
             title: '',
             message: '',
         }
     },
     methods: {
         alert(e){
             window.alert(1)
         },
         addToast(e){
             this.$store.commit('addMessage', {title: this.title, titleMuted: '', message: this.message})
         }
     }
 }
</script>
